<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Name</label>
      <div class="col-3">
            <input type="text" v-model="form.name" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Credit Card</label>
      <div class="col-3">
            <input type="text" v-model="form.creditcard" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Month</label>
      <div class="col-2">
            <input type="text" v-model="form.month" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Year</label>
      <div class="col-2">
            <input type="text" v-model="form.year" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">CVV</label>
      <div class="col-2">
            <input type="text" v-model="form.pin" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Card Code</label>
      <div class="col-2">
            <input type="text" v-model="form.password" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-1 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { encode, decode } from "../util.js"

export default {
  name: "CreditCardEdit",
  data: function () {
    return {
      form: {
        id: this.$route.query.id || ""
      },
      breadcrumbs: [{ name: "Credit Card", link: "/creditcard" }, { name: (this.$route.query.id ? "Update" : "New") + " Credit Card" }],
      showModal: false,
      currentId: "",
      alertContent: "",
      alertClass: "alert-danger",
      type: "Best Buy",
      retailerMap: {1: "Amazon", 2: "Best Buy", 3: "Target", 4: "Sams Club", 5: "Sephora", 6: "eBay", 7: "Staples"}
    };
  },
  mounted() {
      if (this.form.id) {
         this.getObject();
      }
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      this.form.creditcard = encode(this.form.creditcard);
      this.form.pin = encode(this.form.pin);
      axios.post("/creditcard", this.form)
        .then(() => {
          this.submitted = false;
          this.alertClass = "alert-success";
          this.alertContent = "Credit Card has been saved successfully!";
        })
        .catch((error) => {
          this.alertClass = "alert-danger";
          this.alertContent = error.response ? error.response.data : error;
          this.submitted = false;
        });
    },
    getObject() {
      axios
      .get("/creditcard/" + this.form.id)
      .then((response) => {
        this.form = response.data;
        this.form.creditcard = decode(this.form.creditcard);
        this.form.pin = decode(this.form.pin);
      })
      .catch((error) => {
        this.alertClass = "alert-danger";
        this.alertContent = error.response ? error.response.data : error;
      });
    }
  },
};
</script>
